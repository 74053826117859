<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column">
      <v-text-field
        :disabled="!computedShowConnect"
        class="client-id"
        :label="$t('t.ClientId')"
        v-model="computedClientId"
        :rules="clientIdRules"
        :clearable="true"
      />
      <v-text-field
        :disabled="!computedShowConnect"
        class="client-secret"
        :label="$t('t.ClientSecret')"
        v-model="computedClientSecret"
        :rules="clientIdRules"
        :type="isNew ? 'text' : 'password'"
        :clearable="true"
      />
      <v-text-field
        v-if="computedUsername"
        class="username mt-4"
        :label="$t('t.Username')"
        v-model="computedUsername"
        :disabled="true"
      />
      <div class="d-flex flex-column">
        <v-switch
          :disabled="!computedShowConnect"
          dense
          :label="$t('t.CanSendEmail')"
          v-model="computedSendEmail"
        />
        <number-field
          :number.sync="computedMessageMaxMegabytes"
          :label="$t('t.MessageMaxMegabytes')"
          class="max-megabytes"
          :rules="messageMaxMegabytesRules"
          :disabled="!computedSendEmail"
        />
      </div>
      <div class="d-flex flex-column mb-4">
        <v-switch
          :disabled="!computedShowConnect"
          dense
          :label="$t('t.CanReceiveEmail')"
          v-model="computedReceiveEmail"
        />
        <v-switch
          :disabled="!computedReceiveEmail || !computedShowConnect"
          dense
          :label="$t('t.DeleteIncomingMailFromServer')"
          v-model="computedDeleteEmailFromServer"
        />
      </div>
      <div class="d-flex align-end">
        <v-btn
          :disabled="clientSecretChanged || isNew || !computedCanConnectDisconnect"
          v-if="computedShowConnect"
          rounded
          justify-center
          color="primary"
          @click="connect()"
          v-ripple
          :loading="isWorking"
        >
          <v-icon class="mr-2">{{$icon('i.Login')}}</v-icon>
          <span>{{$t('t.Connect')}}</span>
        </v-btn>
        <v-btn
          :disabled="!computedCanConnectDisconnect"
          v-if="computedShowReconnect"
          class="mr-4"
          rounded
          justify-center
          color="error"
          @click="connect()"
          v-ripple
          :loading="isWorking"
        >
          <v-icon class="mr-2">{{$icon('i.Login')}}</v-icon>
          <span>{{$t('t.ExpiredReSignIn')}}</span>
        </v-btn>
        <v-btn
          v-if="!computedShowConnect"
          rounded
          justify-center
          color="primary"
          @click="disconnect()"
          v-ripple
          :loading="isWorking"
        >
          <v-icon class="mr-2">{{$icon('i.Logout')}}</v-icon>
          <span>{{$t('t.Disconnect')}}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    NumberField: () => import('@/components/number-field')
  },
  data () {
    return {
      clientSecretChanged: false,
      dataClientId: null,
      dataClientSecret: null,
      dataDeleteEmailFromServer: false,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataMessageMaxMegabytes: 20,
      dataReceiveEmail: false,
      dataSendEmail: false,
      dataTokenState: 'unsigned',
      dataUsername: null,
      isWorking: false,
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')]
    }
  },
  computed: {
    messageMaxMegabytesRules () {
      return [
        v => (v >= 1 && v <= this.dataMessageMaxMegabytes) || this.$t('t.InvalidValueRange', { min: 1, max: this.dataMessageMaxMegabytes })
      ]
    },
    computedMessageMaxMegabytes: {
      get () {
        return this.dataMessageMaxMegabytes
      },
      set (v) {
        this.dataMessageMaxMegabytes = v
        this.dataDocumentEmit()
      }
    },
    computedCanConnectDisconnect () {
      return this.computedSendEmail || this.computedReceiveEmail || this.computedDeleteEmailFromServer
    },
    computedUsername: {
      get () {
        return this.dataUsername
      }
    },
    computedShowConnect () {
      return this.dataTokenState !== 'signed'
    },
    computedShowReconnect () {
      return this.dataTokenState === 'expired'
    },
    computedClientSecret: {
      get () {
        return this.dataClientSecret
      },
      set (v) {
        this.clientSecretChanged = this.dataClientSecret !== v

        this.dataClientSecret = v
        this.dataDocumentEmit()
      }
    },
    computedClientId: {
      get () {
        return this.dataClientId
      },
      set (v) {
        this.dataClientId = v
        this.dataDocumentEmit()
      }
    },
    clientIdRules () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 250
      ]
    },
    computedReceiveEmail: {
      get () {
        return this.dataReceiveEmail
      },
      set (v) {
        this.dataReceiveEmail = v

        if (!v) {
          this.dataDeleteEmailFromServer = false
        }

        this.dataDocumentEmit()
      }
    },
    computedSendEmail: {
      get () {
        return this.dataSendEmail
      },
      set (v) {
        this.dataSendEmail = v
        this.dataDocumentEmit()
      }
    },
    computedDeleteEmailFromServer: {
      get () {
        return this.dataDeleteEmailFromServer
      },
      set (v) {
        this.dataDeleteEmailFromServer = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ty: 'google',
        v: {
          clientId: this.dataClientId,
          clientSecret: this.dataClientSecret,
          clientSecretChanged: this.clientSecretChanged,
          deleteEmailFromServer: this.dataDeleteEmailFromServer,
          messageMaxMegabytes: this.dataMessageMaxMegabytes,
          receiveEmail: this.dataReceiveEmail,
          sendEmail: this.dataSendEmail,
          tokenState: this.dataTokenState,
          username: this.dataUsername
        }
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    connect () {
      this.isWorking = true
      const redirect = encodeURIComponent(window.location)
      const url = `${window.location.protocol}//${window.location.host}/api/core/providers/${this.$route.params.id}/google/authentication?redirect=${redirect}`
      window.location = url
    },
    async disconnect () {
      this.isWorking = true
      await this.$http().put(`/core/providers/${this.$route.params.id}/disconnect`)
        .then(() => {
          this.dataTokenState = 'unsigned'
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SuccessConfirmation', { title: this.$t('t.SignOut') }))
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.response?.data?.message)
        })
        .finally(() => {
          this.isWorking = false
        })
    }
  },
  props: {
    document: Object,
    isNew: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    document: {
      immediate: true,
      handler (d) {
        const v = d?.v
        this.clientSecretChanged = v?.clientSecretChanged || false
        this.dataClientId = v?.clientId
        this.dataClientSecret = v?.clientSecret
        this.dataDeleteEmailFromServer = v?.deleteEmailFromServer
        this.dataMessageMaxMegabytes = v?.messageMaxMegabytes || 20
        this.dataReceiveEmail = v?.receiveEmail || false
        this.dataSendEmail = v?.sendEmail || false
        this.dataTokenState = v?.tokenState || 'unsigned'
        this.dataUsername = v?.username
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.username
  max-width 30%

.max-megabytes
  max-width 160px
</style>
